@import "./../../assets/scss/variables.scss";

.cont {
  display: flex;
  position: fixed;
  align-items: center;
  top: 50px;
  width: 100%;
  justify-content: stretch;
}

.main {
  height: calc(100vh - #{$header_height});
  position: relative;
  flex: 1;
  overflow-y: scroll;
}

.audio-player-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  .rhap_container {
    width: 80%;
    background: #072250;

    @media (min-width: $tb_bp) {
      width: 40%;
    }
  }
}

