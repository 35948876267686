@import "./../../assets/scss/variables.scss";

.logout-container {
  .MuiBackdrop-root {
    background: rgba($white, 0.5);
  }

  .makeStyles-backdrop-1 {
    color: $secondary-color;
  }
}
