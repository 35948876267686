@import "./../../assets/scss/variables.scss";

.empty-list-element {
  background: $light-bg;
  color: $black;
  text-align: center;
  padding: 20px;
  width: calc(100% - 40px);
  position: absolute;
  top: 50px;
  font-weight: 700;
  margin: 20px;
}
