.alertSnackbar__alert {
    position: fixed;
    bottom: 10px;
    right: 0;
}

.alertSnackbar__snackbar {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}

.alertSnackbar__alert:empty,
.alertSnackbar__snackbar:empty {
    display: none;
}
