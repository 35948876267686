@import "./../../assets/scss/variables.scss";

.header {
  height: $header_height;
  background: $white;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 20px 12px 0;
  align-items: center;
  border-bottom: 2px solid $light-bg;
  position: relative;

  &__button {
    display: flex;
    border: 0;
    background: transparent;
    font-size: 14px;
    font-weight: bold;
    align-items: center;
    cursor: pointer;
    outline: none;
  }

  &__menu {
    position: absolute;
    top: 55px;
    left: 40px;
    background: #FFF;
    z-index: 100;
    width: 155px;
    border-radius: 4px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    li:first-child {
      padding: 0;
      border-bottom: 1px solid $primary-color;
    }

    a {
      display: block;
      color: $primary-color;
      font-size: 13px;
      text-decoration: none;
      padding: 10px 14px;
      opacity: 0.8;
      transition: 0.3s all;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__logo {
    min-height: 50px;
    color: $primary-color;
    font-size: 1rem;
    display: flex;
    align-items: center;
    font-weight: bold;

    img {
      width: 28px;
      height: auto;
      margin: 0 12px 0;
    }

    i {
      display: block;
      margin: 4px 0 0 6px;
    }

    h2 {
      font-size: 14px;
      font-weight: bold;
    }
  }

  &__search {
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid $border-color;

    i {
      cursor: pointer;
      font-size: 24px;
    }
  }

  &__avatar {
    cursor: pointer;
    display: flex;
    align-items: center;

    .MuiAvatar-root {
      width: 35px;
      height: 35px;
    }

    &__name-wrap {
      font-size: 14px;
      color: #000;
      margin-right: 12px;
      text-align: right;
      line-height: 1.2;
    }

    &__last {
      font-weight: 700;
    }
  }

  &__title {
    font-size: 14px;
    color: $primary-color;
    font-weight: bold;
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0 0 0 50px;
  }
}
