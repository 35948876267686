// Font paths
// -------------------------

@font-face {
  font-family: "salesCRM";
  src: url("../fonts/salesCRM.eot");
  src: url("../fonts/salesCRM.eot?#iefix") format("embedded-opentype"),
  url("../fonts/salesCRM.woff2") format("woff2"),
  url("../fonts/salesCRM.woff") format("woff"),
  url("../fonts/salesCRM.ttf") format("truetype"),
  url("../fonts/salesCRM.svg#font") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "salesCRM";
    src: url("../fonts/salesCRM.svg#salesCRM") format("svg");
  }
}
