.cui_sign-up_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.cui_sign-up_left-side {
  width: 35%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 10px;
}

.cui_sign-up_right-side {
  width: 65%;
  height: 100%;
  background: #ffffff33;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.cui_sign-up_logo {
  width: 150px;
  height: 150px;
}

.cui_sign-up_title {
  margin: 20px;
  color: black;
  font-size: 30px;
}

.cui_sign-up_form {
  padding: 25px 50px;
  width: 100%;
}

.cui_sign-up_right-side .MuiGrid-container {
  justify-content: space-between;
}
.cui_sign-up_right-side .MuiGrid-item {
  max-width: 45%;
  padding: 0 !important;
}
.cui_sign-up_right-side .MuiGrid-item .MuiInputBase-input {
  padding-left: 6px;
  font-size: 18px;
}
.cui_sign-up_right-side .MuiTextField-root {
  width: 100%;
}

.cui_sign-up_right-side .cui_sign-up_button-wrap {
  margin-top: 40px;
  text-align: center;
}

.cui_sign-up_forgot span.MuiTouchRipple-root {
  display: none;
}

.cui_sign-up_right-side .cui_sign-up_forgot:hover {
  background: transparent;
}

.cui_sign-up_right-side .cui_sign-up_login {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  margin-top: 15px;
}

.cui_sign-up_right-side .cui_sign-up_login .MuiButton-root {
  font-size: 16px;
  color: #fff;
  text-transform: capitalize;
  text-decoration: underline;
  font-weight: 700;
  padding: 0;
}

.cui_sign-up_right-side .cui_sign-up_login .MuiButton-root:hover {
  background: transparent;
  text-decoration: none;
}

.cui_sign-up_right-side span.MuiTouchRipple-root {
  display: none;
}

.cui_sign-up_right-side .cui_sign-up_create:hover {
  background: transparent;
}

.cui_sign-up_right-side .MuiFormLabel-root {
  color: white !important;
}

.cui_sign-up_right-side .MuiTextField-root {
  margin-bottom: 15px !important;
}

.cui_sign-up_right-side .MuiInputBase-input {
  color: white !important;
}

.cui_sign-up_right-side .MuiInput-underline:before {
  border-bottom: 1px solid white !important;
}

.cui_sign-up_right-side .MuiButton-contained {
  width: 100%;
  margin: 0 20px;
  max-width: 450px;
  border: 1px solid #707070;
  border-radius: 2px;
  text-transform: capitalize;
  background-color: #fff;
  box-shadow: none;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  height: 50px;
  line-height: 50px;
}

.cui_sign-up_right-side .MuiButton-contained.Mui-disabled {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.cui_sign-up_right-side .MuiFormHelperText-root.Mui-error {
  position: absolute;
  top: 100%;
  margin-top: 0;
}
