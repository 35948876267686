@import "./../../assets/scss/variables.scss";

.singleViewCampaign {
    position: fixed;
    z-index: 100;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 500px;
    width: 100%;
    background: $white;
    border-left: 1px solid rgba($grey-text, 0.5);
    overflow: auto;
    color: $txt-color;
    padding-top: 54px;
    overscroll-behavior: contain;

    &__close {
        position: fixed;
        right: 10px;
        top: 10px;
        color: $white;
        //background: #000;
        z-index: 10;

        button {
            padding: 5px;
            border-radius: 0;
            background: transparent;
            transition: all 0.2s ease-in-out;
            color: $white;

            &:hover {
                color: $secondary-color-light;
            }

            .MuiTouchRipple-child {
                border-radius: 5px;
            }
        }
    }

    &__header {
        padding: 7px 15px;
        min-height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: $primary-color;
        color: $white;
        position: fixed;
        top: 0;
        width: inherit;
        z-index: 1;

        div:first-child {
            display: flex;
        }

        button {
            font-size: 12px;
            padding: 8px 15px;
            border: none;
        }

        //button,
        //a {
        //  color: $white;
        //  text-decoration: none;
        //  background: none;
        //  border: none;
        //  transition: all 0.2s ease-in-out;
        //  cursor: pointer;
        //
        //  &:hover,
        //  &:focus,
        //  &:active {
        //    text-decoration: none;
        //    background: none;
        //    border: none;
        //    outline: none;
        //  }
        //
        //  &:hover {
        //    color: $secondary-color-light;
        //  }
        //}
    }

    &__title2 {
        h4 {
            font-weight: 400;
            font-size: 16px;
            margin: 0;
            margin-bottom: -4px;
        }

        span {
            font-size: 14px;
        }
    }

    &__plane {
        margin: 30px;
        background: white;
        border: 1px solid rgba($grey-text, 0.5);

        header {
            padding: 7px 15px;
            background: $secondary-color;
            color: $white;
            margin: -1px;
            display: flex;
            justify-content: space-between;

            button,
            a {
                color: $white;
                text-decoration: none;
                background: none;
                border: none;
                transition: all 0.2s ease-in-out;
                cursor: pointer;

                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                    background: none;
                    border: none;
                    outline: none;
                }

                &:hover {
                    color: $secondary-color-light;
                }
            }
        }

        &-inside {
            padding: 20px;
            font-size: 14px;

            &.editing {
                .MuiTextField-root {
                    &:hover {
                        border-color: transparent;

                        .MuiInputAdornment-root {
                            opacity: 0;
                            pointer-events: none;
                        }
                    }
                }
            }
        }

        &-row1 {
            display: flex;
            margin: 8px 0;

            > span {
                padding: 5px;

                &:first-child {
                    display: inline-block;
                    min-width: 140px;
                    padding: 5px 0;
                }
            }

            button,
            a {
                margin-top: 10px;
                font-size: 12px;
                font-weight: 700;
                color: $secondary-color;

                text-decoration: underline;
                background: none;
                border: none;
                transition: all 0.2s ease-in-out;
                cursor: pointer;

                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                    background: none;
                    border: none;
                    outline: none;
                }

                &:hover {
                    color: $secondary-color-light;
                }
            }

            .MuiInput-underline {
                &:before,
                &:after {
                    display: none;
                }
            }

            .MuiSelect-select {
                padding: 5px 20px 5px 5px;
                font-size: 14px;
                color: $txt-color;

                &:focus {
                    background: transparent;
                }
            }

            .MuiRating-root {
                color: $primary-color;
            }

            .text-field-element {
                position: relative;

                .MuiFormHelperText-root {
                    position: absolute;
                    left: 5px;
                    bottom: -13px;
                    font-size: 10px;
                }

                .MuiInputBase-multiline {
                    padding: 0 !important;
                }
            }
        }

        &-row2 {
            margin: 8px 0;
            border-bottom: 1px solid rgba($grey-text, 0.2);
            padding-bottom: 10px;
            margin-bottom: 10px;

            span {
                display: block;
            }

            span:last-child {
                font-size: 12px;
                color: $grey-text;
            }
        }
    }

    .input2 {
        flex: 1;
        margin: 2px 10px 17px 0;
        font-size: 1px;

        .MuiInput-underline:before {
            border-color: #ccc !important;
        }

        label,
        input {
            font-size: 14px;
            color: #999;
        }

        &.note-input {
            label,
            input {
                color: $txt-color;
            }
        }
    }
}
