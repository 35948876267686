.login-container {
  text-align: center;
}
.cui_signup_user_logo {
  width: 150px;
  height: 150px;
}
.cui_signup_user_title {
  margin: 20px;
  color: black;
  font-size: 30px;
}
.cui_signup_user_dialog {
  align-items: center;
  background-color: #022140;
}

.cui_signup_user_form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 25px 50px;
  min-height: 159px;
}

.cui_signup_user_dialog span.MuiTouchRipple-root {
  display: none;
}

.cui_signup_user_dialog .MuiFormLabel-root {
  color: white !important;
}

.cui_signup_user_dialog .MuiTextField-root {
  margin-bottom: 15px !important;
}

.cui_signup_user_dialog .MuiInputBase-input {
  color: white !important;
}

.cui_signup_user_dialog .MuiInput-underline:before {
  border-bottom: 1px solid white !important;
}

.cui_signup_user_dialog .MuiButton-contained {
  margin-top: 10px !important;
}

.cui_signup_user_dialog .MuiButton-contained.Mui-disabled {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.cui_signup_user_dialog .cui_signup_user_login {
  color: #fff;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 400;
  text-transform: none;
}
