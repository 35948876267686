$white: #fff;
$black: #000;
$main-text: #3f4141;
$grey-text: #848787;
$light-bg: #f2f2f2;
$txt-color: #3f4141;
$border-color: #dfe1e1;

//New Color
$primary-color: #022140;
$secondary-color: #265077;
$secondary-color-light: #52a7f6;
$error-color: #f30202;
$success-color: #0ba205;

$sidebar_size: 200px;
$sidebar_size_sm: 50px;
$header_height: 50px;
$xs_bp: 468px;
$sp_bp: 768px;
$tb_bp: 992px;
$dt_bp: 1242px;

%transition {
  transition: all 0.2s ease-in-out;
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root("::-webkit-input-placeholder") {
    @content;
  }

  @include optional-at-root(":-moz-placeholder") {
    @content;
  }

  @include optional-at-root("::-moz-placeholder") {
    @content;
  }

  @include optional-at-root(":-ms-input-placeholder") {
    @content;
  }
}
