@import "./../../assets/scss/variables.scss";

.text-field-element {
  position: relative;
  padding-right: 45px;
  width: 100%;

  &__actions {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    i {
      font-size: 18px;
      color: $secondary-color;
      cursor: pointer;

      &:last-child {
        margin-left: 3px;
      }

      &.validate {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  .MuiTextField-root {
    margin: 0 !important;
    padding: 5px;
    border: 1px solid transparent;
    border-radius: 3px;
    width: 100%;
    @extend %transition;

    .MuiInputAdornment-root {
      cursor: pointer;
      opacity: 0;
      pointer-events: none;
      @extend %transition;
    }

    &:hover {
      border-color: $grey-text;

      .MuiInputAdornment-root {
        opacity: 1;
        pointer-events: auto;
      }
    }

    .MuiInput-root {
      &:before,
      &:after {
        display: none;
      }

      .MuiInputBase-input {
        padding: 0;
        color: $main-text !important;
        font-size: 14px;

        &::placeholder {
          color: $main-text;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $main-text;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: $main-text;
        }
      }
    }

    &.edit {
      border-color: $grey-text !important;

      .MuiInputAdornment-root {
        opacity: 0;
        pointer-events: none;
      }
    }

    &.error {
      border-color: $error-color !important;
    }
  }
}
