@import "./../../assets/scss/variables.scss";

.container-home {
  width: 100%;
  padding-right: 15px;
  padding-left: 30px;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: $tb_bp) {
    flex-wrap: nowrap;
  }
}

.widget {
  width: 100%;
  flex-basis: 100%;
  margin: 10px;
  padding-bottom: 30px;
  min-height: 200px;
  position: relative;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0px 1px 1px 0px rgba(0, 0, 0, .14), 0px 1px 3px 0px rgba(0, 0, 0, .12);

  @media (min-width: $tb_bp) {
    flex-basis: calc(50% - 20px);
  }

  .MuiTableCell-head,
  .MuiTableCell-body {
    color: $grey-text;
    font-size: 12px;
    padding: 3px 24px 3px 16px;
  }

  header {
    background: $light-bg;
    padding: 7px 15px;
    display: flex;
    justify-content: space-between;

    h3 {
      font-weight: 500;
      margin: 0;
      font-size: 14px;
    }
  }

  .MuiCircularProgress-root {
    position: absolute;
    top: 75px;
    left: 50%;
  }
}
