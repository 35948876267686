@import "./../../assets/scss/variables.scss";

.campaignTrackView {
  padding: 40px 15px 0;

  header {
    padding: 45px 0 30px;
    position: sticky;
    top: 0;
    background: $white;
    z-index: 1;

    p {
      color: $txt-color;
    }
  }
  &__filters {
    display: flex;

    .MuiFormControl-root {
      flex: 1;
      margin-right: 30px;

      &:first-child {
        max-width: 350px;
      }
    }

    .MuiFormGroup-root {
      display: flex;
      flex-direction: row;
    }

    .MuiFormControlLabel-label {
      font-size: 16px;
      font-weight: 500;
      margin: 0 10px 0 0;
    }
  }

  &__report {
    padding: 50px 0 30px;
  }

  .traffic-row {
    border-bottom: 1px solid #dfe1e1;
    padding: 5px;
    margin-left: 0;
    margin-right: 0;

    .title {
      color: $secondary-color;
      font-weight: 700;
      font-size: 0.875rem;
    }
    .subTitle {
      font-size: 0.625rem;
      &__sub {
        color: gray;
        font-size: 0.625rem;
      }
      a {
        cursor: pointer;
        color: $secondary-color-light;
        text-decoration: none;
      }
      img {
        width: 50px;
        height: 50px;
      }
    }

    a {
      cursor: pointer;
      color: $secondary-color-light;
      text-decoration: none;
    }
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: $txt-color;
  }
}
