@import "./../../assets/scss/variables.scss";

.leftNav {
  background: $primary-color;
  width: $sidebar_size;
  height: calc(100vh - 50px);
  transition: 0.3s all;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.mobile {
    width: $sidebar_size_sm;
  }

  &__back {
    color: $white;
    cursor: pointer;
    width: 34px;
    margin-left: auto;

    .icons8-back {
      display: block;
      margin: 35px 0 0 0;

      .mobile & {
        display: none;
      }
    }

    .icons8-menu {
      display: none;
      margin: 35px 0 0 0;

      .mobile & {
        display: block;
      }
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;

    i {
      font-size: 18px;
      margin: 0 15px;
      color: #b0fbfb;
    }
  }

  &__bottom {
    margin-bottom: 20px;
  }

  &__link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $white;
    padding: 10px 0;
    font-size: 15px;
    cursor: pointer;
    opacity: 0.8;
    position: relative;

    span {
      display: inline;
      white-space: nowrap;

      .mobile & {
        display: none;
      }
    }

    &:visited,
    &:hover,
    &:active {
      color: $white;
    }

    &:hover {
      opacity: 1;
    }

    b {
      display: inline-block !important;
      position: absolute;
      top: 17px;
      left: 15px;
      color: $primary-color;
      font-size: 12px;
    }
  }

  &__linkActive {
    background: $secondary-color;
  }
}
