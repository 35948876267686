@import "./../../assets/scss/variables";

.pagination {
  padding: 20px 15px;
  display: flex;
  justify-content: flex-end;

  i {
    color: $main-text;
    cursor: pointer;

    &.disabled {
      color: $border-color;
      cursor: none;
      pointer-events: none;
    }

    &:last-child {
      margin-left: 10px;
    }
  }
}

.empty-table {
  margin-top: 100px;
}
