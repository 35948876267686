// Icons
// -------------------------

.icons8-dashboard-layout:before,
.icons8-verified-account:before,
.icons8-user-group-2:before,
.icons8-onboarding:before,
.icons8-more-than:before,
.icons8-expand-arrow:before,
.icons8-collapse-arrow:before,
.icons8-back:before,
.icons8-plus-math:before,
.icons8-subtract:before,
.icons8-power-off-button:before,
.icons8-hide:before,
.icons8-eye:before,
.icons8-cancel:before,
.icons8-checked:before,
.icons8-edit:before,
.icons8-business-documentation:before,
.icons8-export:before,
.icons8-slider:before,
.icons8-customer:before,
.icons8-strike:before,
.icons8-call-list:before,
.icons8-call:before,
.icons8-edit-2:before,
.icons8-trash-can:before,
.icons8-cancel-2:before,
.icons8-circled-play:before,
.icons8-back-2:before,
.icons8-chevron-right:before,
.icons8-chevron-left:before,
.icons8-phone-contact:before,
.icons8-search:before,
.icons8-download:before,
.icons8-menu:before,
.icons8-add-phone:before,
.icons8-external-link:before,
.icons8-external-link-2:before,
.icons8-upload:before,
[data-icons8]:before {
  @include icons8-font;
}

[data-icons8]:before {
  content: attr(data-icons8);
}

.icons8-dashboard-layout:before {
  content: $icons8-var-dashboard-layout;
}
.icons8-verified-account:before {
  content: $icons8-var-verified-account;
}
.icons8-user-group-2:before {
  content: $icons8-var-user-group-2;
}
.icons8-onboarding:before {
  content: $icons8-var-onboarding;
}
.icons8-more-than:before {
  content: $icons8-var-more-than;
}
.icons8-expand-arrow:before {
  content: $icons8-var-expand-arrow;
}
.icons8-collapse-arrow:before {
  content: $icons8-var-collapse-arrow;
}
.icons8-back:before {
  content: $icons8-var-back;
}
.icons8-plus-math:before {
  content: $icons8-var-plus-math;
}
.icons8-subtract:before {
  content: $icons8-var-subtract;
}
.icons8-power-off-button:before {
  content: $icons8-var-power-off-button;
}
.icons8-hide:before {
  content: $icons8-var-hide;
}
.icons8-eye:before {
  content: $icons8-var-eye;
}
.icons8-cancel:before {
  content: $icons8-var-cancel;
}
.icons8-checked:before {
  content: $icons8-var-checked;
}
.icons8-edit:before {
  content: $icons8-var-edit;
}
.icons8-business-documentation:before {
  content: $icons8-var-business-documentation;
}
.icons8-export:before {
  content: $icons8-var-export;
}
.icons8-slider:before {
  content: $icons8-var-slider;
}
.icons8-customer:before {
  content: $icons8-var-customer;
}
.icons8-strike:before {
  content: $icons8-var-strike;
}
.icons8-call-list:before {
  content: $icons8-var-call-list;
}
.icons8-call:before {
  content: $icons8-var-call;
}
.icons8-edit-2:before {
  content: $icons8-var-edit-2;
}
.icons8-trash-can:before {
  content: $icons8-var-trash-can;
}
.icons8-cancel-2:before {
  content: $icons8-var-cancel-2;
}
.icons8-circled-play:before {
  content: $icons8-var-circled-play;
}
.icons8-back-2:before {
  content: $icons8-var-back-2;
}
.icons8-chevron-right:before {
  content: $icons8-var-chevron-right;
}
.icons8-chevron-left:before {
  content: $icons8-var-chevron-left;
}
.icons8-phone-contact:before {
  content: $icons8-var-phone-contact;
}
.icons8-search:before {
  content: $icons8-var-search;
}
.icons8-download:before {
  content: $icons8-var-download;
}
.icons8-menu:before {
  content: $icons8-var-menu;
}
.icons8-add-phone:before {
  content: $icons8-var-add-phone;
}
.icons8-external-link:before {
  content: $icons8-var-external-link;
}
.icons8-external-link-2:before {
  content: $icons8-var-external-link-2;
}
.icons8-upload:before {
  content: $icons8-var-upload;
}
