@import "./../../assets/scss/variables.scss";

.contactComponent {
  margin: 30px;
  background: white;
  border: 1px solid rgba($grey-text, 0.5);

  header {
    padding: 7px 15px;
    background: $secondary-color;
    color: $white;
    margin: -1px;
    display: flex;
    justify-content: space-between;

    button,
    a {
      color: $white;
      text-decoration: none;
      background: none;
      border: none;
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        background: none;
        border: none;
        outline: none;
      }

      &:hover {
        color: $secondary-color-light;
      }
    }
  }

  &__plane-inside {
    padding: 20px;
    font-size: 14px;

    &.editing {
      .MuiTextField-root {
        &:hover {
          border-color: transparent;

          .MuiInputAdornment-root {
            opacity: 0;
            pointer-events: none;
          }
        }
      }
    }
  }

  &-row1 {
    display: flex;
    margin: 8px 0;

    > span {
      padding: 5px;

      &:first-child {
        display: inline-block;
        min-width: 140px;
        padding: 5px 0;
      }
    }

    button,
    a {
      margin-top: 10px;
      font-size: 12px;
      font-weight: 700;
      color: $secondary-color;

      text-decoration: underline;
      background: none;
      border: none;
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        background: none;
        border: none;
        outline: none;
      }

      &:hover {
        color: $secondary-color-light;
      }
    }

    .MuiInput-underline {
      &:before,
      &:after {
        display: none;
      }
    }

    .MuiSelect-select {
      padding: 5px 20px 5px 5px;
      font-size: 14px;
      color: $txt-color;

      &:focus {
        background: transparent;
      }
    }

    .MuiRating-root {
      color: $primary-color;
    }

    .text-field-element {
      position: relative;

      .MuiFormHelperText-root {
        position: absolute;
        left: 5px;
        bottom: -13px;
        font-size: 10px;
      }

      .MuiInputBase-multiline {
        padding: 0 !important;
      }
    }
  }

  &-row2 {
    margin: 8px 0;
    border-bottom: 1px solid rgba($grey-text, 0.2);
    padding-bottom: 10px;
    margin-bottom: 10px;

    span {
      display: block;
    }

    span:last-child {
      font-size: 12px;
      color: $grey-text;
    }
  }

  &__form {
    background: #f2f2f2;
    padding: 17px 20px;
    border-radius: 5px;
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
    margin-bottom: 12px;

    &.notes {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__rowForm {
    display: flex;
    flex-direction: row;
    margin: 0;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    height: 65px;

    .MuiTextField-root {
      &:last-child {
        margin-left: 20px;
      }
      .MuiInput-root {
        &:before {
          border-bottom: 1px solid $border-color !important;
        }

        &:hover {
          &:before {
            border-bottom: 1px solid $secondary-color !important;
          }
        }
      }
    }
    &.no-left-margin {
      .MuiTextField-root {
        margin-left: 0;
      }
    }
  }

  &__btnWrap {
    display: flex;
    justify-content: flex-end;

    button {
      text-transform: none;
      font-size: 16px;
      line-height: 40px;
      padding: 0;
      color: #fff;
      background: $secondary-color;
      border-radius: 2px;
      min-width: 150px;

      &:hover {
        background: $secondary-color;
      }

      &:last-child {
        margin-left: 10px;
      }
    }
  }

  &__rowContacts {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid rgba($grey-text, 0.2);
    padding: 6px 0 4px 0;
  }

  &__colInfo {
    display: flex;
    flex-direction: column;
    font-size: 12px;

    i {
      cursor: pointer;
      font-size: 14px;
    }

    p {
      margin: 0 0 2px 0;
    }

    &--bigger-text {
      font-size: 14px;
    }
  }

  &__colAction {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    align-self: stretch;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;

      &.disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: .5;
      }
    }

    li {
      padding: 0 5px;
      cursor: pointer;

      a {
        color: $txt-color;
      }
    }

    span {
      font-size: 12px;
    }
  }
}
