@import "./../../assets/scss/variables.scss";

.assigned {

  &__form {
    margin: 0;

    h3 {
      margin: 10px 0;
      font-size: 14px;
      color: $main-text;
      font-weight: normal;
    }

    p {
      font-size: 12px;
      color: $main-text;
    }

    .MuiTextField-root {
      width: 100%;
    }

    .MuiInput-root {
      width: 100%;
      margin-bottom: 11px;
    }

    .Mui-disabled.MuiInputLabel-formControl {
      transform: none;
    }
  }

  &__btnText {
    display: block;
    width: 100px;
    border: 0;
    background: transparent;
    color: $secondary-color-light;
    text-align: center;
    text-decoration: underline;
    margin: 0 auto;
    cursor: pointer;
  }

  &__btnWrap {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    button {
      text-transform: none;
      font-size: 16px;
      line-height: 40px;
      padding: 0;
      color: #fff;
      background: $secondary-color;
      border-radius: 2px;
      min-width: 150px;

      &:hover {
        background: $secondary-color;
      }

      &.cancel {
        color: $main-text;
        background: #FFF;
        margin-left: 10px;
      }

    }
  }

  &__frame {
    border: 1px solid rgba(132, 135, 135, 0.5);
    padding: 10px;
  }
}
