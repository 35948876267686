// Variables
// --------------------------

$icons8-css-prefix: "icons8";
$icons8-font-name: "salesCRM";
$icons8-font-path: "../fonts";

$icons8-var-dashboard-layout: "\f100";
$icons8-var-verified-account: "\f102";
$icons8-var-user-group-2: "\f103";
$icons8-var-onboarding: "\f105";
$icons8-var-more-than: "\f106";
$icons8-var-expand-arrow: "\f107";
$icons8-var-collapse-arrow: "\f108";
$icons8-var-back: "\f109";
$icons8-var-plus-math: "\f10a";
$icons8-var-subtract: "\f10b";
$icons8-var-power-off-button: "\f10c";
$icons8-var-hide: "\f10d";
$icons8-var-eye: "\f10e";
$icons8-var-cancel: "\f10f";
$icons8-var-checked: "\f110";
$icons8-var-edit: "\f111";
$icons8-var-business-documentation: "\f113";
$icons8-var-export: "\f114";
$icons8-var-slider: "\f115";
$icons8-var-customer: "\f116";
$icons8-var-strike: "\f117";
$icons8-var-call-list: "\f11a";
$icons8-var-call: "\f11b";
$icons8-var-edit-2: "\f11c";
$icons8-var-trash-can: "\f11d";
$icons8-var-cancel-2: "\f11e";
$icons8-var-circled-play: "\f11f";
$icons8-var-back-2: "\f120";
$icons8-var-chevron-right: "\f121";
$icons8-var-chevron-left: "\f122";
$icons8-var-phone-contact: "\f123";
$icons8-var-search: "\f124";
$icons8-var-download: "\f126";
$icons8-var-menu: "\f127";
$icons8-var-add-phone: "\f129";
$icons8-var-external-link: "\f12a";
$icons8-var-external-link-2: "\f12b";
$icons8-var-upload: "\f12c";
