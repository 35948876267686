@import "./../../assets/scss/variables.scss";

.campaign-list {
    .add-campaign {
        min-width: 144px;
        margin-left: 15px;
    }

    .table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 75px 15px 0;

        &__page-title {
            color: $txt-color;
            font-size: 16px;
        }

        & + .t-cont {
            padding-top: 15px;
        }
    }
}
