:root {
  --grey-text: #848787;
  --txt-color: #3f4141;
  --white: #ffffff;
  --primary-color: #022140;
  --secondary-color: #265077;
  --secondary-color-light: #52a7f6;
  --border-color: #dfe1e1;
  --black: #000;
  --error-color: #f30202;
  --success-color: #0ba205;
}

.profileDialog {
  position: fixed;
  z-index: 100;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  overflow: auto;
  max-width: 500px;
  width: 100%;
  border-left: 1px solid rgba(var(--grey-text), 0.5);
}

.profileDialog__header {
  padding: 8px 10px 8px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--primary-color);
  color: var(--white);
}

.profileDialog button {
  padding: 5px;
}

.profileDialog__title h4 {
  font-size: 20px;
  margin: 0;
  font-weight: 400;
}

.profileDialog__close svg {
  fill: var(--white);
}

.profileDialog__container {
   margin: 20px 25px;
   background: white;
}

.profileDialog__avatar {
  position: relative;
  display: flex;
  justify-content: center;
}

.profileDialog__avatar .MuiAvatar-root {
  width: 75px;
  height: 75px;
}

.profileDialog__editButton {
  position: relative;
  width: 75px;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
}

.profileDialog__editButton i {
  position: absolute;
  top: 0;
  right: -10px;
}

.profileDialog__resetPassword {
   border: 0;
   background: none;
   padding: 0;
   margin-bottom: 40px;
   outline: none;
   text-decoration: underline;
   cursor: pointer;
}
.profileDialog__msg--error {
  color: var(--error-color);
}
.profileDialog__msg--success {
  color: var(--success-color);
}

.profileDialog__form {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-bottom: 0;
}


.profileDialog__form .MuiFormControl-root {
  margin: 0 0 11px;
}

.profileDialog__form .MuiInput-underline:before {
  border-color: var(--border-color);
}

.profileDialog__form label {
  font-size: 14px;
}

.profileDialog__form_text {
   color: var(--grey-text);
   font-size: 11px;
}

.profileDialog a {
  display: inline-block;
  margin-bottom: 30px;
}

.profileDialog__btn_container {
   margin: 15px -15px 30px;
   display: flex;
}

.profileDialog__btn_container button {
  flex: 1;
  margin: 0 15px;
}

.profileDialog .MuiInputAdornment-root button {
  font-size: 10px;
  color: var(--black);
  padding: 4px;
}

.profileDialog .MuiInputAdornment-root svg {
  width: 0.7em;
  height: 0.7em;
}

.profileDialog__btn,
.profileDialog__btn2 {
  background-color: var(--secondary-color);
  text-transform: none;
  font-weight: 300;
  border-radius: 0;
  font-size: 16px;
}

.profileDialog__btn:hover,
.profileDialog__btn2:hover {
   color: var(--white);
   background-color: var(--primary-color);
}

.profileDialog__btn.updated,
.profileDialog__btn2.updated {
   pointer-events: none;
   background-color: var(--success-color);
   color: var(--white);
}

.profileDialog__btn2 {
  background-color: transparent;
}

.profileDialog .upload__image-wrapper {
  position: relative;
}

.profileDialog .upload__edit {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .4);
  z-index: 5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: none;
  opacity: 0;
  transition:all .2s ease-in-out;
}

.profileDialog .upload__edit i {
  font-size: 30px;
  color: var(--white);
}

.profileDialog .upload__image-wrapper:hover .upload__edit {
  opacity: 1;
  pointer-events: all;
}

.profileDialog__roles.MuiFormControl-root {
   margin: 20px 0 0;
}

.profileDialog__roles legend {
  font-size: 14px;
}

.profileDialog__roles .MuiTypography-body1 {
  font-size: 14px;
}

.profileDialog__status {
  padding: 10px 0 20px;
  display: flex;
  justify-content: space-between;
}

.profileDialog__status button {
  border: 0;
  background: transparent;
  padding: 0;
  text-decoration: underline;
  color: var(--secondary-color-light);
  cursor: pointer;
}

.profileDialog .sip-details__title {
   color: var(--txt-color);
   font-size: 14px;
   margin-bottom: 15px;
}

.profileDialog .sip-details .MuiFormControl-root .MuiInput-underline.Mui-disabled:before {
  border-bottom-style: solid !important;
}

.profileDialog .sip-details .MuiFormControl-root .MuiInputBase-root {
  position: relative;
}

.profileDialog .sip-details .MuiFormControl-root .MuiInputBase-root.Mui-disabled {
   color: var(--txt-color) !important;
}

.profileDialog .sip-details .MuiFormHelperText-root {
  position: absolute;
  left: 0;
  bottom: -20px;
}

.profileDialog button.profileDialog__btn {
  /*margin-top: 20px;*/
}
