@import "./../../../assets/scss/variables";

.filterDialog {
  position: fixed;
  z-index: 10;
  right: 0;
  top: 50px;
  bottom: 0;
  background: white;
  overflow: auto;
  width: 300px;
  border-left: 1px solid rgba($grey-text, 0.5);
  overscroll-behavior: contain;

  &__header {
    background-color: $primary-color;
    display: flex;
    flex-direction: row;
    padding: 12px 14px;
    justify-content: space-between;
    color: $white;
    align-items: center;
  }

  &__close {
    color: $white !important;
    padding: 0 !important;
  }

  &__title {
    font-size: 20px;
    line-height: 1.3;
    font-weight: 700;
  }

  &__container {
    padding: 22px 10px;
    display: flex;
    flex-direction: column;
  }

  &__container-form {
    & > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
  }

  &__input-title {
    color: $grey-text;
    font-size: 14px;

    &.checkbox-title {
      display: block;
    }
  }

  &__date-input {
    border: 1px solid $border-color !important;
    border-radius: 2px !important;
    padding: 0 9px !important;
    width: 180px;

    .MuiInput-underline:before,
    .MuiInput-underline:after {
      display: none;
    }
  }

  &__checkbox-wrap {
    margin-top: 28px;
    flex-direction: column !important;
    align-items: flex-start !important;
    margin-bottom: 33px !important;

    & > div {
      margin-bottom: 11px;
      display: flex;
      align-items: center;

      em {
        margin-left: 7px;
        display: inline-block;
        color: $main-text;
        font-size: 14px;
        line-height: 1.3;
        font-style: normal;
      }
    }

    .MuiCheckbox-root,
    .MuiRadio-root {
      padding: 0 !important;
    }
  }

  &__reset-filter-btn {
    width: 120px;
    height: 35px;
    color: $secondary-color !important;
    border: 1px solid $secondary-color !important;
    border-radius: 2px !important;
    font-size: 14px !important;
    line-height: 35px !important;
    text-align: center;
    text-transform: capitalize !important;
  }

  &__filter-btn {
    width: 120px;
    height: 35px;
    color: $white !important;
    border-radius: 2px !important;
    background-color: $secondary-color !important;
    font-size: 14px !important;
    line-height: 35px !important;
    text-align: center;
    text-transform: capitalize !important;
  }
}
