@import "./../../assets/scss/variables";

.top-calls {
  display: flex;
  justify-content: space-between;
  padding: 25px 15px 0;
  align-items: center;

  &__page-title {
    color: $txt-color;
    font-size: 16px;
  }

  &__table-wrap {
    position: relative;
  }

  .filter-btn {
    background-color: $secondary-color;
    text-transform: none;
    border-radius: 3px;
    font-size: 14px;
    padding-left: 30px;
    position: relative;

    &:before {
      content: "\f115";
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      font-family: "salesCRM";
    }
  }

  .clear-filter-btn {
    color: $primary-color;
    font-size: 14px;
    text-decoration: underline;
    text-transform: capitalize;
    margin-right: 20px;
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }

  &__icon {
    margin-left: 15px;
    cursor: pointer;
    font-size: 12px;
  }

  &__filters {
    display: flex;
    padding: 30px 15px 0;
    align-items: center;
    flex-wrap: wrap;

    &__title {
      color: $txt-color;
      font-size: 14px;
      line-height: 1.3;
      margin-right: 10px;
      margin-bottom: 5px;
    }

    &__item {
      padding: 5px 5px 6px 10px;
      border: 1px solid $primary-color;
      border-radius: 30px;
      margin-right: 20px;
      font-size: 10px;
      color: $primary-color;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      line-height: 1.3;
    }
  }
  &__pagination {
    padding: 20px 15px;
    display: flex;
    justify-content: flex-end;

    i {
      color: $main-text;
      cursor: pointer;

      &.disabled {
        color: $border-color;
        cursor: none;
        pointer-events: none;
      }

      &:last-child {
        margin-left: 10px;
      }
    }
  }
}
