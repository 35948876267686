@import "../../assets/scss/variables.scss";

.t-cont {
  padding: 40px 15px 0 15px;
}

.table {
  width: 100%;

  .MuiTableBody-root {
    tr.MuiTableRow-root {
      cursor: pointer;

      &.no-event {
        cursor: auto;
      }
    }
  }

  .active {
    background: #eee;
  }

  .play-icon {
    z-index: 9999;
    font-size: 20px;
    cursor: pointer;
    color: $txt-color;
    margin-right: 5px;
  }
}
