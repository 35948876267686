@import "./variables.scss";

.containerAuth {
  height: 100vh;
  display: flex;
  background: linear-gradient($primary-color, $secondary-color);
}

.signup-container {
  height: 100vh;
  display: flex;
  background: linear-gradient($primary-color, $secondary-color);
}

.table {
  padding-top: 20px;

  .MuiTableHead-root {
    background: #f2f2f2;
    border-radius: 0;
  }
  .MuiTableCell-head {
    color: $black;
  }

  .MuiTableCell-body {
    color: $grey-text;
    font-size: 0.8rem;
  }
}

.tabs {
  width: 100%;
  background: $light-bg;
  position: fixed;

  .MuiTab-root {
    background: $light-bg;
    text-transform: unset;
  }

  .MuiTab-textColorPrimary.Mui-selected {
    color: $primary_color;
    background: #fff;
  }

  .MuiTabs-indicator {
    top: 0;
    color: $secondary-color;
  }

  + .container {
    padding-top: 60px;
  }
}

.c-checkbox {
  .MuiSvgIcon-root {
    font-size: 1rem;
  }

  .PrivateSwitchBase-root-1 {
    padding: 5px;
  }
  .MuiTypography-body1 {
    font-size: 0.7rem;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body .add-button {
  text-transform: none;
  background-color: $secondary-color;
  border-radius: 3px;
  font-size: 14px;
  position: relative;
}

.tabs {
  z-index: 2;
}
