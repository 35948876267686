@import "../../../assets/scss/variables.scss";

.mobile-table {
  font-size: 12px;
  color: $main-text;
  padding-top: 15px;

  &__item {
    margin-bottom: 7px;
    padding: 4px 0 8px;
    border-bottom: 1px solid $border-color;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &--left,
    &--right {
      max-width: 50%;
    }

    &--right {
      text-align: right;
    }

    &--center {
      width: 100%;
      justify-self: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 10px;
      margin-top: 5px;

      & > div:last-child {
        text-align: right;
      }
    }

    &--inner {
      &__name {
        font-size: 16px;
        font-weight: 700;
      }

      &__created {
        margin-bottom: 4px;
      }

      &__email {
        margin-top: 6px;
      }

      &__dateCreated {
        font-weight: 700;
        margin-bottom: 5px;
      }

      &__status {
        margin-bottom: 5px;
      }

      &__recording {
        margin-right: 5px;
      }

      &__record-time {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        min-width: 55px;
      }
    }
  }
}
