@import "../../assets/scss/variables.scss";

.add-new-campaign {
  position: fixed;
  z-index: 100;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  overflow: auto;
  max-width: 400px;
  width: 100%;
  border-left: 1px solid rgba($grey-text, 0.5);

  &__header {
    padding: 8px 10px 8px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $primary-color;
    color: $white;
  }

  &__title {
    h4 {
      font-size: 20px;
      margin: 0;
      font-weight: 400;
    }
  }

  .add-new-campaign__close-button {
    color: $white;
    padding: 5px;
  }

  &__container {
    margin: 20px 35px 20px 25px;
    background: $white;
  }

  &__btn_container {
    margin: 20px -15px 30px;
    display: flex;

    button {
      flex: 1;
      margin: 0 15px;
      text-transform: capitalize;
      background-color: $secondary-color;
      border-radius: 2px;
      border: 1px solid $primary-color;
    }
  }

  .profileDialog__btn {
    background-color: $secondary-color;
    text-transform: none;
    font-weight: 300;
    border-radius: 0;
    font-size: 16px;

    &:hover {
      color: $white;
      background-color: $primary-color;
    }

    &.updated {
      pointer-events: none;
      background-color: $success-color;
      color: $white;
    }
  }

  .MuiFormControl-root {
    margin-bottom: 15px;
    width: 100%;
  }

  &__copy_container {
    position: relative;

    span {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 12px;
      cursor: pointer;
      z-index: 999;
    }
  }

}
