.login-container {
  text-align: center;
}
.cui_login_logo {
  width: 150px;
  height: 150px;
}
.cui_login_title {
  margin: 20px;
  color: black;
  font-size: 30px;
}
.cui_login_dialog {
  align-items: center;
  background-color: #022140;
}

.cui_login_form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 25px 50px;
  min-height: 200px;
}

.cui_login_dialog .cui_login_forgot {
  text-align: right;
  font-size: 11px;
  text-decoration: none;
  color: #fff;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  text-transform: capitalize;
  justify-content: flex-end;
  font-weight: 400;
  margin: -8px 0 10px;
  width: max-content;
  align-self: flex-end;
}
.cui_login_forgot span.MuiTouchRipple-root {
  display: none;
}

.cui_login_dialog .cui_login_forgot:hover {
  background: transparent;
}

.cui_login_dialog .cui_login_create {
  margin-top: 10px;
  padding: 0;
  text-align: center;
  font-size: 14px;
  color: #fff;
  text-transform: inherit;
  text-decoration: underline;
  font-weight: 400;
}

.cui_login_dialog span.MuiTouchRipple-root {
  display: none;
}

.cui_login_dialog .cui_login_create:hover {
  background: transparent;
}

.cui_login_dialog .MuiFormLabel-root {
  color: white !important;
}

.cui_login_dialog .MuiTextField-root {
  margin-bottom: 15px !important;
}

.cui_login_dialog .MuiInputBase-input {
  color: white !important;
}

.cui_login_dialog .MuiInput-underline:before {
  border-bottom: 1px solid white !important;
}

.cui_login_dialog .MuiButton-contained {
  margin-top: 10px !important;
}

.cui_login_dialog .MuiButton-contained.Mui-disabled {
  background-color: rgba(255, 255, 255, 0.8) !important;
}
