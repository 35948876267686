@import './../../assets/scss/variables.scss';

.searchDialog {
    position: fixed;
    z-index: 10;
    right: 0;
    top: 0;
    bottom: 0;
    background: white;
    overflow: auto;
    width: 100%;
    max-width: 500px;
    border-left: 1px solid rgba($grey-text, 0.5);

    &__header {
        padding: 5px 15px 9px 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: $primary-color;
        color: $white;

        button {
            padding: 5px;
        }

        form,
        Input,
        i {
            color: #fff;
        }

        i {
            cursor: pointer;
        }
    }

    &__form {
        flex: 1;
        margin-right: 10px;
    }

    .searchDialog__close {
        color: $white;
    }

    &__container {
        margin: 20px 15px;
        background: white;
    }

    &__form {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        margin-bottom: 0px;

        .MuiFormControl-root {
            margin: 0 0 11px;
        }

        .MuiInput-underline:before {
            border-color: $border-color;
        }

        .MuiInput-underline:hover:not(.Mui-disabled):before {
            border-bottom: 1px solid $white;
        }

        label {
            color: $white;
            font-size: 14px;
        }
    }

    .search__result {
        background: #eee;
        padding: 8px 25px;
        font-size: 14px;
    }

    .search__list {
        list-style: none;
        margin: 5px 0;
        padding: 0;
        font-size: 12px;
        color: $grey-text;
        
        li {
            & + li {
                margin-top: 10px;
                padding-top: 10px;
                border-top: 1px solid $border-color;
            }
        }

        h5 {
            color: $main-text;
            font-size: 16px;
            margin: 0;
        }

        &-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &-content {
            font-size: 13px;
        }

        footer {
            font-size: 10px;
        }
    }

}
