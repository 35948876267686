@import "./../../assets/scss/variables.scss";

.forgot-password-container {
  text-align: center;
  .title {
    margin: 20px;
    color: $white;
    font-size: 20px;
  }
  .dialog {
    align-items: center;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.2);
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px;
    min-height: 200px;

    Button {
      background: $white;
      color: $primary-color;
    }
  }
}
